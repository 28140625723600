import React, { useEffect } from "react"
import Layout from "../components/layout"
import logo from "../assets/logo.svg";
import dinoIllustration from "../assets/dinosaurillustration.svg";
import personIllustration from "../assets/personAsset.svg";
import gridIllustration from "../assets/gridAsset.svg";
import eiffelIllustration from "../assets/eiffeltowerAsset.svg";
import {Link} from "gatsby"

import indexStyles from "./index.module.scss"
import indexExplanationStyles from "./indexexplanations.module.scss"
import { motion, useAnimation, useViewportScroll, useTransform } from "framer-motion"
import SEO from "../components/seo"
import { faChartLine, faChevronDown, faChild, faPiggyBank, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default () => {

    const { scrollY } = useViewportScroll();
    const yRange =  useTransform(scrollY, scrollLength => scrollLength > 200 ? 0 : 1-(scrollLength / 200));
    const controls = useAnimation();
     useEffect(() => {
        controls.start(i => ({
            scaleY: [1, 0.6, 1],
            translateY: [0, -2, 0],
            transition: { delay: i * 0.2,
                loop: Infinity,
                repeatDelay: 1.1,
                duration: 1
            },
        }))
    }, []) 



    return (
        <div >
        <Layout yRange={yRange}>
                <SEO
                    title={"Phil Cohn"}
                    description={'Freelance augmented reality creator and 2D/3D artist'}
                    image={'/static/favicon.ico'}
                />
            <div className={indexStyles.introContainer}>
                <div className={indexStyles.logoContainer}>

                    <motion.svg 
                        opacity= {1}
                        className={indexStyles.logo}
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 458.34 57.24">

                        <motion.use
                            custom={0} 
                            animate={controls}
                            href={`${logo}#top`}
                            />
                        <motion.use
                            custom={1} 
                            animate={controls}
                            href={`${logo}#upper`}
                            />
                        <motion.use
                            custom={2} 
                            animate={controls}
                            href={`${logo}#middle`}
                            />
                        <motion.use
                            custom={3} 
                            animate={controls}
                            href={`${logo}#lower`}
                            />
                        <motion.use
                            custom={4} 
                            animate={controls}
                            href={`${logo}#bottom`}
                            />
                    </motion.svg>
                    <h1 className={indexStyles.personalSubheading}>Freelance augmented reality creator</h1>
     

                </div>
      
            </div>

        </Layout>
        <div className = {indexStyles.downArrow} >
                    <motion.div style={{ opacity: yRange}}>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </motion.div>
                </div>
         <div className={indexExplanationStyles.explanation1}>
             <h1>What is AR?</h1>
             <h2>Augmented reality (AR for short) is a way of superimposing digital content over the real world.</h2>
             <div className={indexExplanationStyles.diagram}>
                 <div className={indexExplanationStyles.firstText}><p>Cutting-edge algorithms use AI and camera information to gather info about the real world.</p></div>

                 <img 
                    src={dinoIllustration} alt="An illustration of a dinosaur appearing on a table in augmented reality">
                </img>
                 <div className={indexExplanationStyles.secondText}><p>AR allows you to create content that’s placed on, in and around real objects that is easily viewable on your phone.</p></div>

             </div>
        </div>

        <div className={indexExplanationStyles.explanation2}>
             <h1>What can AR do?</h1>
             <h2>AR software can recognise real-world objects such as:</h2>
             <div className={indexExplanationStyles.arUses}>
                <div>
                    <h3>Face, body and hands</h3>
                    <img src={personIllustration}></img>
                </div>
                <div>
                    <h3>Horizontal surfaces such as 
                        floors and tables</h3>
                    <img src={gridIllustration}></img>
                </div>
                <div>
                    <h3>Real world landmarks and marker images!</h3>
                    <img className={indexExplanationStyles.eiffel} src={eiffelIllustration}></img>
                </div>
             </div>
        </div>

        <div className={indexExplanationStyles.explanation3}>
             <h1>How can AR help me?</h1>
             <h2>AR software can recognise real-world objects such as:</h2>
             <div className={indexExplanationStyles.arStats}>
                <div className={indexExplanationStyles.topLeft}>
                    <FontAwesomeIcon icon={faChild} />
                    <a href="https://www.zappar.com/blog/how-augmented-reality-affects-brain/"
                        target="_blank" rel="noopener noreferrer">
                            <h3>AR almost doubles the levels of attention in users. Engage your audience! </h3>
                        </a>
                    
                   
                </div>
                <div className={indexExplanationStyles.topRight}>
                    <FontAwesomeIcon icon={faUsers} />
                    <a href="https://forbusiness.snapchat.com/"
                        target="_blank" rel="noopener noreferrer">
                           <h3>Snapchat reaches 75% of millennials and Gen Z.</h3>
                        </a>
                    
                </div>
                <div className={indexExplanationStyles.bottomLeft}>
                    <FontAwesomeIcon icon={faChartLine} />
                    <a href="https://www.mckinsey.com/industries/retail/our-insights/adapting-to-the-next-normal-in-retail-the-customer-experience-imperative#"
                        target="_blank" rel="noopener noreferrer">
                           <h3> Customers that use AR to try out products are up to 250% more likely to convert than ones that don't.</h3>
                        </a>
                </div>
                <div className={indexExplanationStyles.bottomRight}>
                    <FontAwesomeIcon icon={faPiggyBank} />
                    <a href="https://digitalintheround.com/augmented-reality-statistics/"
                        target="_blank" rel="noopener noreferrer">
                        <h3>The size of the AR market is estimated to be over $198 billion in 2025.</h3>
                    </a>
                </div>
             </div>
        </div>

        <div className={indexExplanationStyles.explanation4}>
            <div>
                <h1>What are the options for using AR?</h1>
                <div className={indexExplanationStyles.arOptions}>
                    <div className={indexExplanationStyles.socialAr}>
                        <h2>Social AR (Snapchat, Instagram, Facebook effects)</h2>
                        <p>User either scans a QR code, or discovers the experience through a paid ad. 
                            This takes the user to a custom AR effect in the social media app they use.</p>
                        <ul>
                            <li>Good for adverts, reach</li>
                            <li>Lots of powerful features</li>
                            <li>Requires users to have one of the apps installed (Snapchat, Instragram or Facebook)</li>
                        </ul>
                    </div>

                    <div className={indexExplanationStyles.webAr}>
                        <h2>Web AR</h2>
                        <p>User scans a QR code and is taken straight to the AR experience on a webpage. The user is then prompted to give permissions to use their camera, and the AR experience plays. </p>
                        <ul>
                            <li>Platform independent</li>
                            <li>Fewer features compared to social (especially snapchat)</li>
                        </ul>
                    </div>
                    <div className={indexExplanationStyles.ctaLinks}>
                        <a className={indexExplanationStyles.ctaButton}
                            href="mailto:philippetercohn@gmail.com" 
                            target="_blank" rel="noopener noreferrer">
                            Get in touch to discuss your next AR project!
                        </a>
                        <Link className={indexExplanationStyles.ctaButton}
                            to={`/work/`}>
                            See some projects I've worked on.
                        </Link>
                    </div>
                </div>
            </div>
        </div>
     </div>
    )
}
   


